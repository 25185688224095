@use './variables';

.button-container-personal-data {
    display: flex;
    gap: 10px; /* Pas de ruimte tussen de knoppen aan indien nodig */
}
body {
    font-family: "Assistant", sans-serif;
    background-color: $white !important;
}

.ui-autocomplete-loading:read-only {
    background-image: none !important;
}

.fa {
    font-weight: normal;
}

html,
body {
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
}

body {
    min-width: 320px;
    color: #142F4B;
    background: #FAFBF6;
}
.text-description {
    overflow-wrap: break-word; 
}

.overlay-for-popup {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: black;
    background-color: rgba(0, 0, 0, .75)
}
.main-content {
    width: 100%;
    flex: 1; /* Take the remaining width */
    overflow-y: auto; /* Add vertical scroll if needed */
    
}

.link {
    font-weight: bold;
    font-size: 17px;
}

.mr-2 {
    margin-right: 5px;
}

.breadcrumb {
    background-color: #FAFBF6;
    color: #e0a00f;
}

#primarycontact-confirmation-modal {
    width: 1000px;
}

.input-agreement-wrapper {
    float: left;
}

.input-agreement-wrapper .flex-prin-checkbox_label {
    display: inline;
}

.input-agreement-wrapper > *:hover {
    cursor: pointer;
}

.warning {
    color: red;
    font-weight: bold;
}

.button-save-warning-container {
    align-items: center;
}

.save-warning {
    display: inline-block;
    color: red;
    margin-left: 5px;
}

.warning-choice {
    border-color: red;
    border-width: medium;
}

.date-sub {
    font-size: 11px;
    letter-spacing: 1px;
    text-align: right;
    text-transform: uppercase;
}

.text-sub {
    margin-top: 5px;
    margin-bottom: 5px;
    color: #ABAFB3;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.title-bold-big {
    color: #142F4B;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.text-sub.aww-warning {
    color: #d64242;
    margin-top: initial;
}

.text-container-left {
    width: calc(50% - 20px);
    float: left !important;
}

.text-container-right {
    width: calc(50% - 20px);
    float: left !important;
    margin-top: 10px;
}


@media (max-width: 768px) {
    .text-container-left,
    .text-container-right {
        width: 100%;
        float: none !important;
        margin-top: 10px;
    }

    .mobile-visible {
        display: block;
    }

    .mobile-hidden {
        display: none;
    }

    header .brand {
        top: -100px;
    }

    .user-container {
        right: 10px;
    }

    .search-bar {
        margin: auto;
        max-width: 50%;
    }

    .btn.btn-search{
        max-width: 50px;
    }

    .main-menu-list {
        padding: 0;
        flex-wrap: nowrap;
    }

    .main-menu-list .main-menu-item {
        width: calc(100% - 10px);
        height: calc(50% - 10px);
    }

    .main-menu.expanded .main-menu-list {
        display: none;
    }

    .main-menu.expanded-mobile .main-menu-list {
        display: block;
    }

    .main-menu .main-menu-item a {
        padding: 10px;
    }

    .main-menu .main-menu-item .main-menu-item-title {
        font-size: 14px;
    }

    .main-menu .main-menu-item .main-menu-item-desc {
        display: none;
    }

    .logo a,
    .logo span {
        padding: 5px 5px 5px 10px;
    }

    .logo a {
        width: auto;
    }

    .logo span {
        padding-left: 20px;
    }

    .mobile-menu {
        display: none;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .panel.panel-default {
        box-shadow: none;
        margin: 0;
        border-top: 0;
    }

    .row :first-child .panel.panel-default {
        border-top: 1px solid #DDD;
    }

    .panel.panel-default .panel-heading {
        border: 0;
    }

    .panel.panel-default .panel-heading a {
        padding: 30px 20px;
    }

    .panel.panel-default .panel-body {
        display: none;
    }

    section .section-header {
        padding: 10px 10px;
    }

    section .section-actions {
        display: flex;
        margin-bottom: 5px;
        padding: 10px 10px;
        justify-content: flex-start;
    }

    section .section-header .section-header-title,
    h1 {
        margin: 0;
        font-size: 20px;
    }

    section .section-header .section-header-postscript {
        position: static;
        padding: 5px 0;
    }
    .table > thead > tr > th {
        padding: 10px 10px;
    }

    .table > tbody > tr > td {
        padding: 10px 10px;
        font-size: 13px;
    }

    .table-search {
        width: 100%;
    }

    .table-search input[type="text"] {
        width: calc(100% - 130px);
        display: inline-block;
    }
    
    .table thead th,
    .table tbody td {
        padding: 10px; 
        font-size: 13px;
    }

    .itaa-logo{
        max-width: 65%;
    }
}

.mobile-visible {
    display: none;
}

/* SEARCHBAR*/
.search-results {
    position: absolute;
    width: 40%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    p {
        padding: 10px;
        margin: 0;
        cursor: pointer;
        transition: background-color 250ms;

        &:hover {
            background-color: #f5f5f5;
        }
    }
}


/* CUSTOM SCROLLBAR OVERWRITE */

.mCSB_inside > .mCSB_container {
    margin-right: 0 !important;
}

/* HEADER */

header {
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.95);
    border-bottom: 2px solid #DDD;

    .container {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 0;
    }

    .brand {
        z-index: 1;
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 67px;
        height: 67px;
        padding: 13px 0 0 0;
        box-sizing: border-box;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        background: #142F4B;
        border: 4px solid #D39D5D;
        border-radius: 50%;
        transition: top 500ms;
    }
}

.popup-wrapper {
    position: fixed;
    top: 20px;
    right: 50px;
    z-index: 9999;
    width: fit-content; /* Zorg ervoor dat de pop-up bovenop andere elementen staat */
  }


/* CONTAINER */

.container {
    max-width: none;
    margin-top: 1rem !important;
}

.card {
    border-radius: 0.25rem;
}

.card-title {
    font-size: 1.15rem;
    margin-bottom: 0.75rem;
}

.card-text {
    margin-bottom: 2rem;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
}

/* USER CONTAINER */

#user {
    top: 5px;
}

.user-container {
    position: absolute;
    right: 30px;
    display: table;
    padding-right: 15px;
    transition: right 500ms;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        display: block;
        width: 0;
        margin-top: -2px;
        z-index: 1;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #142F4B transparent;
        transition: transform 350ms;
    }

    &.active:after {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    .user-photo {
        display: table-cell;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
    }

    .user-name {
        position: relative;
        display: table-cell;
        height: 50px;
        padding: 5px;
        font-size: 15px;
        vertical-align: middle;
        cursor: pointer;
    }

    .user-menu {
        z-index: 2;
        position: absolute;
        top: 46px;
        right: -12px;
        box-sizing: border-box;
        width: 210px;
        padding: 0 10px;
        border: 1px solid #E6E6E6;
        background: #FFF;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

        .user-menu-item a {
            display: block;
            padding: 18px 0;
            color: #142F4B;
            font-size: 15px;
            text-decoration: none;
            border-top: 1px solid #E6E6E6;
            transition: color 250ms;

            &:hover {
                color: $itaa-gold;
            }
        }

        .user-menu-item:first-of-type a {
            border: 0;
        }
    }
}

@media(max-width: 668px) {
    .user-container .user-name {
        display: none;
    }
}

/* SEARCH CONTAINER */
.search-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: table;
    padding-right: 15px;
    transition: right 500ms;
    cursor: pointer;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.search-icon {
    position: absolute;
    right: 10px;
}

.search-bar input[type="text"] {
    padding-left: 30px;
    pointer-events: auto;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

/* MAIN MENU */
#main {
    padding: 5px;
    margin-bottom: 75px;
}

.main-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
}

/* MAIN MENU > MAIN MENU TRIGGER */

.main-menu-trigger {
    position: absolute;
    top: -60px;
    left: 0;
    width: 61px;
    height: 60px;
    background-color: #142F4B;
    background-image: url(../assets/menu.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px;
    border-right: 1px solid #142F4B;
    transition: background-color 250ms;
    cursor: pointer;
}

.main-menu-trigger.active {
    background-color: #142F4B;
}

@media(min-width: 768px) {

    .main-menu-trigger:hover,
    .main-menu-trigger.active,
    .main-menu.visible .main-menu-trigger {
        background-color: #142F4B;
    }
}

.list-group-data
{
    position: absolute !important;
    top: 65% !important;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* MAIN MENU LIST */
.main-menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #FAFBF6;
    color: #FFF;
    padding: 10px;
    overflow-x: auto;
}

.main-menu-list .main-menu-item {
    width: calc(25% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    height: 120px;
    box-sizing: border-box;
    border: solid rgba(255, 255, 255, 0.2);
    border-width: 0 1px 1px 0;
    background-color: #142F4B;
}

.main-menu-list .default {
    background-color: #142F4B;
}

.main-menu .main-menu-list .gold {
    background-color: #D39D5D;
}

.main-menu .main-menu-list .gold a {
    color: #142F4B;
}

.main-menu .main-menu-list .gold a .main-menu-item-desc {
    font-weight: 800;
}

.main-menu-list .light {
    background-color: #0274BE;
}

.main-menu-list .itaa-border {
    border: 5px solid #D39D5D;
}

.main-menu-list .single-item {
    width: 100%;
}

.main-menu .main-menu-item a {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    color: #FFF;
    transition: background-color 250ms;
}

.main-menu .main-menu-item a:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.1);
}

.main-menu .main-menu-item .main-menu-item-title {
    font-size: 17px;
    word-break: break-all;
}

.main-menu .main-menu-item .main-menu-item-desc {
    font-size: 15px;
    font-weight: 300;
    opacity: 0.5;
}

.logo {
    position: relative;
    top: 7px;
    left: 5px;
    display: table;
    height: auto;
}

.logo a,
.logo span {
    display: table-cell;
    box-sizing: border-box;
    padding: 5px 20px;
    font-size: 17px;
    line-height: 1;
    vertical-align: middle;
}

.logo a {
    position: relative;
    width: 150px;
    color: #FFF;
    text-decoration: none;
    background-color: #D39D5D;
}

.logo a:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 30px 0 30px 10px;
    border-color: transparent #D39D5D;
}

.logo a:hover {
    text-decoration: none;
}

.logo span {
    padding-left: 30px;
    color: #D39D5D;
}

/* INTRO */

intro:before {
    clear: both;
    content: "";
    display: block;
}

/* MOBILE MENU */

.mobile-menu {
    z-index: 99;
    position: fixed;
    top: 60px;
    left: 0;
    display: none;
    width: 100%;
    height: calc(100% - 60px);
    color: #FFF;
    font-size: 15px;
    background: #142F4B;
}

.mobile-menu a.back-link {
    display: block;
    width: 100%;
    padding: 16px 10px 16px 48px;
    color: #FFF;
    background: #42ACD6 url(../assets/left-arrow-white.svg) no-repeat 20px center;
    background-size: 18px auto;
}

.mobile-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-menu ul ul {
    display: none;
}

.mobile-menu ul li {
    position: relative;
    margin: 0;
    padding: 0;
}

.mobile-menu ul li a {
    display: block;
    box-sizing: border-box;
    padding: 16px 56px 16px 16px;
    color: #FFF;
    text-decoration: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-menu li.active {
    background-color: #254780;
}

.mobile-menu .level-trigger {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    /*    background: url(../gfx/plus-white.svg) no-repeat center;
*/ background-size: 18px;
}

.mobile-menu li.active .level-trigger {
    /*    background: url(../gfx/minus-white.svg) no-repeat center;
*/ background-size: 18px;
}

.mobile-menu ul li ul li a {
    padding-left: 40px;
}

/* CONTENT */

#content {
    box-sizing: border-box;
}

#content:after {
    content: '';
    clear: both;
    display: block;
}

/* FOOTER */

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 59px;
    background: rgba(255, 255, 255, 0.9);
    border-top: 1px solid #CCC;

    .button-save-warning-container {
        padding: 10px;
    }
}

.btn-footer {
    box-sizing: border-box;
    float: left;
    z-index: 1000;
    position: fixed;
    bottom: 10px;
    margin: 0 !important;
}

.btn-footer-right {
    right: 10px;
}

/* PANEL */
.row {
    margin-right: -8px;
    margin-left: -5px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    padding: 5px;
    

}

.panel {
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.panel .panel-body {
    padding: 0 20px;
}

.panel .text-sub {
    margin-top: 0;
}

.panel-default > .panel-heading {
    display: table;
    width: calc(100% + 1px);
    height: 60px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #DDD;
    background: #FFF;
}

.panel-default > .panel-heading span,
.panel-default > .panel-heading a {
    display: table-cell;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 20px;
    background: #FFF url(../assets/header-arrow-right-blue.svg) no-repeat;
    background-position: right 20px center;
    color: #142F4B;
    font-size: 17px;
    line-height: 1;
    vertical-align: middle;
    transition: background-color 250ms, color 250ms;
}

.panel-default > .panel-heading span {
    background-image: none;
}

.panel-default > .panel-heading a:hover {
    color: #FFFFFF;
    text-decoration: none;
    background: #142F4B url(../assets/header-arrow-right-white.svg) no-repeat;
    background-position: right 20px center;
}

.panel-default > .panel-body {
    padding: 15px 15px 10px 15px;
    color: #9CA3A9;
    background: #ECEDE8;
}

/* SECTION */

section {
    margin-bottom: 10px;
}

section .section-actions {
    display: flex;
    justify-content: right;
    margin-bottom: 5px;
}

section .section-header {
    position: relative;
}

section .section-header .section-header-title,
h1 {
    font-size: 24px;
    font-weight: 300;
}

section .section-header .section-header-postscript {
    position: absolute;
    top: 6px;
    right: 10px;
    text-transform: none;
    color: #142F4B;
    font-size: 17px;
    font-weight: bold;
}

/* TABLE */

#table {
    margin-top: 1.3rem !important;
}

.table {
    color: #8A9299;
    font-size: 14px;
    background: #FFF;
    border: 1px solid #EEEEEE;
}

.table > thead > tr > th {
    padding: 15px 10px;
    color: #ABAFB3;
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase;
    border-bottom: 0;
}

.table > tbody > tr > td {
    padding: 15px 10px;
    line-height: 1;
    vertical-align: middle;
}

/* TABLE SEARCH */

.table-search {
    width: 500px;
    padding: 0 0 20px 0;
}

.table-search input[type="text"] {
    width: 300px;
    margin: 0;
}

/* PAGINATION */
.pagination .page-item .page-link {
    color: #142F4B;
}

.pagination .page-item.active .page-link {
    background-color: #D39D5D;
    border-color: #D39D5D;
    color: #fff;
}

/* BOOTSTRAP BUTTONS */

.btn {
    margin: 0 2px;
    padding: 10px 20px;
    min-height: 36px;
    color: #142F4B;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1;
    background: #EEE;
    border: 0;
    border-radius: 2px;
    outline: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 250ms;
}

.btn:hover {
    background-color: #DDD;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.btn.btn-search {
    margin: 0 2px;
    padding: 10px 20px;
    color: #FFF;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1;
    background: #142F4B;
    border: 0;
    border-radius: 5px;
    outline: 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 250ms;
}

.btn.btn-search:hover {
    background-color: #0E2338;
}

.btn.btn-success {
    margin: 0 2px;
    padding: 10px 20px;
    color: #FFF;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1;
    background: #D39D5D;
    border: 0;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 250ms;
}

.btn.btn-success:hover {
    background-color: #142F4B;
    color: white;
}

.btn.btn-warning {
    margin: 0 2px;
    padding: 10px 20px;
    color: #FFF;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1;
    background: #f4b220;
    border: 0;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 250ms;
}

.btn.btn-warning:hover {
    background-color: #e5a00b;
}

.btn.btn-danger {
    margin: 0 2px;
    padding: 10px 20px;
    color: #FFF;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1;
    background: #d64242;
    border: 0;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 250ms;
}

.btn.btn-danger:hover {
    background-color: #bc2929;
}

/* BOOTSTRAP LABEL */

span.label {
    color: #142F4B;
    font-size: 11px;
    font-weight: normal;
    background: #DDD;
}

span.label.label-primary {
    color: #FFF;
    background: #142F4B;
}

span.label.label-success {
    color: #FFF;
    background: #D39D5D;
}

span.label.label-info {
    color: #FFF;
    background: #42ACD6;
}

span.label.label-warning {
    color: #FFF;
    background: #f4b220;
}

span.label.label-danger {
    color: #FFF;
    background: #d64242;
}

/* BOOTSTRAP MODAL */
.modal-body {
    margin: 'auto';
}

.modal {
    --bs-modal-width: 608px;
}

.modal-content {
    border-radius: 0;
    overflow: hidden;
}

.modal-content .modal-header {
    padding: 25px 20px;
    border-bottom-color: #F5F5F5;
}

.modal-content .modal-header .modal-title {
    color: #142F4B;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.modal-content .modal-header .close {
    display: inline-block;
    width: 24px;
    height: 90px;
    background-size: 15px;
    border: 0;
    outline: 0;
    opacity: 0.7;
    transition: opacity 250ms;
}

.modal-content .modal-header .close:hover {
    opacity: 1;
}

.modal-content .modal-header .close span {
    display: none;
}

.modal-content .modal-body {
    padding: 25px 20px;
    font-size: 15px;
}

.modal-content .modal-footer {
    padding: 25px 20px;
    border: 0;
}

.nav-tabs > li > a {
    border-radius: 0;
}

.popup-label {
    display: inline-block;
}

.tooltip-inner {
    max-width: none;
}

.glyphicon-info-sign {
    color: #777;
}

.info-format {
    font-size: medium;
    margin-left: 5px;
}

label.required:after {
    color: red;
    content: " *";
}


.card-title {
    display: block;
}

.card-text,
.btn-home {
    display: block;
}


@media (max-width: 767px) {
    .card {
        pointer-events: auto;
    }

    .btn-home {
        display: none;
    }

    .btn-home:not([href]) {
        display: unset !important;
    }

    .card-body {
        padding: 0.5rem;
    }

    .table {
        margin-top: 1.3rem !important;
        color: #8A9299;
        font-size: 14px;
        background: #FFF;
        border: 1px solid #EEEEEE;
        width: 100%;
        border-collapse: collapse;
    }


    .table tbody td {
        padding: 15px 10px;
        line-height: 1;
        vertical-align: middle;
        border-bottom: 1px solid #EEEEEE; 
    }

    .table tbody tr:nth-child(odd) {
        background: #F0F1F2; 
    }

}
