@use './variables';

.table {
    thead {
        tr {
            th {
                color: $black;
                font-size: 1rem;
                padding: 10px 10px;
                text-transform: uppercase;
                border-bottom: none;
                line-height: 1;
                height: 40px;
            }

            th.lastColumn {
                width: 140px;
            }
        }
    }

    tbody {
        tr {
            td {
                background-color: $white;
                padding: 7px 10px;
                height: 27px;
                font-size: 1rem;
            }

            td.lastColumn>* {
                float: right;
            }
        }
    }
}

.pill-badge {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.5em 0.8em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    color: $white;
    margin-right: 0.15rem;
    margin-top: 0.15rem;
    margin-bottom: 0.15rem;

    &.primary {
        background-color: $itaa-blue;
    }

    &.gold {
        background-color: $itaa-gold;
    }

    &.red {
        background-color: $itaa-warning-red;
    }

    button {
        background: $itaa-blue;
        border: none;
        color: $white;
        font-weight: 900;
        padding-right: 0px;
        padding-top: 0px;
    }
}

.name-col {
    max-width: 20%;
    width: 15%;
}

@media only screen and (max-width: 600px) {
    .table {
        min-width: 100%;
        border-collapse: collapse;
        display: block;
        width: 100%;
        overflow-y: hidden;

        tr {
            td.badges-office-composition {
                vertical-align: top;

                .pill-badge primary {
                    display: inline-block;
                    width: fit-content;
                    white-space: normal;
                    word-wrap: break-word;
                    text-align: left;
                }
            }
        }
    }
}

