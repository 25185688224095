@use './variables';

.warning-text {
    color:$itaa-warning-red;
    font-weight: bold;
    display: flex;

    &.inline {
        display: inline;
    }
}

ul.warning-text {
    display: block;

    li {
        list-style-type: disc;
     }
}

#clientele-nb{
    margin-left: 20px;
    font-style: italic;
    text-align: justify;
}

.officeDeterminationText{
    margin-left: 24px;
    font-style: italic;
    text-align: justify;
}

.disableEloket{
    background-color: rgb(228, 227, 227);
    height: 40px;
    color: white;
    font-weight: bolder;

    p {
        margin: 10px;
        text-align: center;
    }
}

.generalAssemblyDocumentationUrls {
    a{
        color: $itaa-blue;
        &:hover{
                color: blue;
                text-decoration: underline !important;
            }
    }
    
}

.download-link {
    color: $itaa-blue !important;
    &:hover {
        text-decoration: underline !important;
    }
}